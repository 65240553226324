<template>
  <HeaderBar />

  <section class="carvia-container">
    <LocationsAndTimes
      :station="station"
      :pickup-location="pickupLocation"
      :dropoff-location="dropoffLocation"
      :start-date="startDate"
      :end-date="endDate"
      :category-filter="categoryFilter"
      :make-filter="makeFilter"
      @change="onLocationsAndTimesChange"
      @changing-filter="changingFilter = $event"
    />

    <VehiclesList
      v-if="station"
      :station="station"
      :pickup-location="pickupLocation"
      :dropoff-location="dropoffLocation"
      :start-date="startDate"
      :end-date="endDate"
      :category-filter="categoryFilter"
      :make-filter="makeFilter"
      :rent-duration="rentDuration"
      :changing-filter="changingFilter"
      class="mt-8"
      @select-offer="onSelectOffer"
    />

    <CreateBookingModal
      v-if="pickupLocation && dropoffLocation && selectedOffer"
      v-model="showModal"
      :car-id="selectedOffer.car.id"
      :station="station"
      :pickup-location="pickupLocation"
      :dropoff-location="dropoffLocation"
      :start-date="startDate"
      :end-date="endDate"
      :rent-duration="rentDuration"
      :transfer-delivery-price="
        selectedOffer.pricing.customBookedExtras?.find(
          (extra) => extra.customVehicleExtraType === VehicleExtraType.TRANSFER_PICKUP,
        )?.price
      "
      :transfer-return-price="
        selectedOffer.pricing.customBookedExtras?.find(
          (extra) => extra.customVehicleExtraType === VehicleExtraType.TRANSFER_RETURN,
        )?.price
      "
      :total-base-price="selectedOffer.pricing.totalBasePrice"
      :total-price="selectedOffer.pricing.totalPrice"
    />
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import LocationsAndTimes, { type LocationsAndTimesProps } from './components/LocationsAndTimes.vue';
import VehiclesList from './components/VehiclesList.vue';
import { DateTime } from 'luxon';
import { ref, watchEffect } from 'vue';
import CreateBookingModal from './components/CreateBookingModal.vue';
import type { Station } from '@/entities/station/station.entity';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { CustomLocations } from '@/constants/custom-locations.constant';
import type { VehicleCategory } from '@/entities/vehicle-category.enum';
import type { Offer } from '@carvia/ros-client-types';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';

const { t } = useI18n();
const uiStore = useUiStore();

const changingFilter = ref(true);

watchEffect(() => {
  uiStore.setHeaderTitle(t('createBooking'));
});

const initialStartDate = DateTime.now()
  .plus({ days: 1 })
  .set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
const startDate = ref(initialStartDate.toJSDate());
const endDate = ref(initialStartDate.plus({ days: 2 }).toJSDate());
const station = ref<Station | CustomLocations | null>(null);
const pickupLocation = ref<BookingLocation | null>(null);
const dropoffLocation = ref<BookingLocation | null>(null);
const categoryFilter = ref<VehicleCategory | null>(null);
const makeFilter = ref<string | null>(null);
const rentDuration = ref<number>(2);
const selectedOffer = ref<Offer | null>(null);
const showModal = ref(false);

const onLocationsAndTimesChange = (result: LocationsAndTimesProps) => {
  station.value = result.station;
  pickupLocation.value = result.pickupLocation;
  dropoffLocation.value = result.dropoffLocation;
  startDate.value = result.startDate;
  endDate.value = result.endDate;
  categoryFilter.value = result.categoryFilter;
  makeFilter.value = result.makeFilter;
  rentDuration.value = Math.ceil(
    DateTime.fromJSDate(result.endDate).diff(DateTime.fromJSDate(result.startDate), 'days').days,
  );
};

const onSelectOffer = (offer: Offer) => {
  selectedOffer.value = offer;
  showModal.value = true;
};
</script>

<i18n lang="json">
{
  "en": {
    "createBooking": "Create Booking"
  },
  "de": {
    "createBooking": "Buchung erstellen"
  }
}
</i18n>
