<template>
  <div>
    <h2>{{ t('blockedDeposit') }}</h2>
    <div v-if="booking?.hasBlockedCreditCard && booking?.blockedCreditCardRefNr">
      <div class="mt-5 grid grid-cols-4 gap-4 rounded-lg border border-primary/40 px-2 pb-1 pt-1.5">
        <div>
          {{ t('blockedAmount') }}
          <span
            v-if="booking.blockedCreditCardAmount"
            v-currency="booking.blockedCreditCardAmount"
            class="font-medium"
          ></span>
          <span v-else class="font-medium">-</span>
        </div>
        <div>
          {{ t('blockedAt') }}
          <span class="font-medium">{{
            formatDateString(booking.blockedCreditCardDate, dateFormat)
          }}</span>
        </div>
        <div>
          {{ t('source') }}
          <span class="font-medium">{{ booking.blockedCreditCardDetails?.source ?? '-' }}</span>
        </div>
        <div>
          {{ t('refNumber') }}
          <span class="font-medium">{{ booking.blockedCreditCardRefNr }}</span>
        </div>
      </div>
      <div class="mt-4 flex justify-end">
        <CVButton
          v-if="booking.blockedCreditCardDetails"
          @click.prevent="showCreditCardDetails = true"
        >
          {{ t('showCreditCardDetails') }}
        </CVButton>
      </div>
    </div>
    <div v-else class="mt-4">
      <p class="mb-4">{{ t('noBlockedDeposit') }}</p>
      <div class="flex items-center justify-end gap-4">
        <p v-if="booking.createdDepositPaymentLinkDate">
          {{ t('sentLinkAt') }}
          {{ formatDateString(booking.createdDepositPaymentLinkDate, dateFormat) }}
        </p>
        <CVButton
          :is-loading="isBlockingAmount"
          :outline="!!booking.createdDepositPaymentLinkDate"
          :disabled="paymentLinkSent"
          @click.prevent="sendPaymentLink"
          >{{ t('sendDepositPaymentLink') }}</CVButton
        >
      </div>
    </div>
    <CreditCardDetailsModal
      v-if="booking.blockedCreditCardDetails"
      v-model="showCreditCardDetails"
      :credit-card-details="booking.blockedCreditCardDetails"
    />
  </div>
</template>

<script setup lang="ts">
import type { Booking } from '@/entities/bookings/booking.entity';
import { AutoChargeType } from '@/entities/payment-terminals/auto-charge-type.enum';
import { useDateFormat } from '@/hooks/use-date-format';
import { useI18n } from 'vue-i18n';
import { useBlockAmount } from '@/queries/use-payment-api';
import { ref } from 'vue';
import CreditCardDetailsModal from './CreditCardDetailsModal.vue';
import {
  selectPaymentLinkReceiver,
  showPaymentLinkToCopy,
} from '@/utils/select-payment-link-receiver';
import { SendPaymentLinkResult } from '@carvia/ros-client-types';
import { formatDateString } from '@/hooks/use-formated-date';

const props = defineProps<{
  booking: Booking;
}>();

const { t } = useI18n();
const dateFormat = useDateFormat();

const paymentLinkSent = ref(false);
const showCreditCardDetails = ref(false);

const { mutateAsync: blockAmount, isPending: isBlockingAmount } = useBlockAmount();

const sendPaymentLink = async () => {
  const paymentLinkReceiver = await selectPaymentLinkReceiver(props.booking);
  if (!paymentLinkReceiver) return;

  const { paymentLink } = await blockAmount({
    bookingId: props.booking.id,
    amount: props.booking.deposit ?? props.booking.car.vehicleType.deposit ?? 0,
    types: [AutoChargeType.PAYMENT_LINK],
    paymentLinkReceiver,
  });
  paymentLinkSent.value = true;
  if (paymentLink?.sendPaymentLinkResult === SendPaymentLinkResult.DISABLED) {
    showPaymentLinkToCopy(paymentLink.url);
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "blockedDeposit": "Blocked Deposit",
    "blockedAmount": "Blocked Amount:",
    "blockedAt": "Blocked at:",
    "refNumber": "Ref Nr:",
    "source": "Source:",
    "sendDepositPaymentLink": "+ Send deposit payment link",
    "noBlockedDeposit": "This booking has no blocked deposit",
    "sentLinkAt": "Sent link at:",
    "showCreditCardDetails": "Show credit card details"
  },
  "de": {
    "blockedDeposit": "Hinterlegte Kaution",
    "blockedAmount": "Blockierter Betrag:",
    "blockedAt": "Blockiert am:",
    "refNumber": "Refnr:",
    "source": "Source:",
    "sendDepositPaymentLink": "+ Kaution Zahlungslink senden",
    "noBlockedDeposit": "Diese Buchung hat keine hinterlegte Kaution",
    "sentLinkAt": "Link gesendet am:",
    "showCreditCardDetails": "Kreditkarten Details anzeigen"
  }
}
</i18n>
