<template>
  <div class="mb-4 mt-6 border border-primary p-4">
    <div class="mb-4 grid grid-cols-3 gap-4 border-b border-primary pb-4">
      <SelectField
        class="col-span-3"
        name="paymentMethod"
        :label="t('paymentMethod')"
        :options="paymentMethodOptions"
      />
      <InputField
        name="amount"
        :label="t('amount')"
        type="number"
        :max-fraction-digits="2"
        :min="0"
        adornment="€"
      />
      <SelectField name="direction" :label="t('direction')" :options="directions" />
      <SelectField
        v-if="!invoiceId"
        name="invoiceId"
        :label="t('invoice')"
        :options="invoicesOptions"
        :empty-value-to-null="true"
      />
    </div>
    <div class="flex justify-end gap-2">
      <CVButton variant="success" :is-loading="isCharging" @click.prevent="chargeAmount">
        {{ confirmButtonText ?? t('chargeAmount') }}
      </CVButton>
      <CVButton variant="warning" :disabled="isCharging" @click.prevent="$emit('onCancel')">
        {{ t('cancel') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Invoice } from '@/entities/invoices/invoice.entity';
import { useI18n } from 'vue-i18n';
import { chargeAmountSchema } from '@/validation/charge-amount.schema';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import InputField from '../InputField.vue';
import SelectField from '../SelectField.vue';
import type { ChargeAmountDto } from '@/entities/payment-terminals/charge-amount.dto';
import { Alert } from '@/utils/alert';
import type { Booking } from '@/entities/bookings/booking.entity';
import { AutoChargeType } from '@/entities/payment-terminals/auto-charge-type.enum';
import { CreditCard } from '@/entities/payment-terminals/credit-card.enum';
import { ChargeAmountPaymentMethod } from '@/entities/payment-terminals/charge-amount-payment-method.enum';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { selectPaymentLinkReceiver } from '@/utils/select-payment-link-receiver';
import { PaymentLinkReceiver } from '@carvia/ros-client-types';

const props = defineProps<{
  booking: Booking;
  invoiceId?: string;
  invoices: Invoice[];
  showOnlinePaymentCard: boolean;
  showBlockedCard: boolean;
  isCharging?: boolean;
  initialAmount?: number;
  confirmButtonText?: string;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: ChargeAmountDto): void;
  (e: 'onCancel'): void;
}>();

const { t } = useI18n();

const paymentMethod = props.showOnlinePaymentCard
  ? props.booking.hasOnlineCreditCardPayment
    ? ChargeAmountPaymentMethod.ONLINE_PAYMENT_CARD
    : ChargeAmountPaymentMethod.PAYMENT_LINK
  : props.booking.hasBlockedCreditCard
    ? ChargeAmountPaymentMethod.DEPOSIT_CARD
    : props.booking.status === BookingStatus.CAR_RETURNED
      ? ChargeAmountPaymentMethod.PAYMENT_LINK
      : null;

const { handleSubmit } = useForm({
  initialValues: {
    paymentMethod,
    invoiceId: null,
    amount: props.initialAmount ?? '',
    direction: '1',
  },
  validationSchema: chargeAmountSchema,
});

const chargeAmount = handleSubmit(async (values) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyChargeTitle'),
    text:
      values.paymentMethod === ChargeAmountPaymentMethod.PAYMENT_LINK
        ? t('reallyChargeViaPaylinkText')
        : t('reallyChargeFromCreditCardText'),
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: t('declineCharge'),
    confirmButtonText: t('confirmCharge'),
  });

  if (!alertResult.isConfirmed) return;

  const type =
    values.paymentMethod === ChargeAmountPaymentMethod.PAYMENT_LINK
      ? AutoChargeType.PAYMENT_LINK
      : AutoChargeType.PSEUDO_NUMBER;
  const creditCardToUse =
    type === AutoChargeType.PSEUDO_NUMBER
      ? values.paymentMethod === ChargeAmountPaymentMethod.DEPOSIT_CARD
        ? CreditCard.DEPOSIT
        : CreditCard.ONLINE_PAYMENT
      : null;

  let paymentLinkReceiver: PaymentLinkReceiver | undefined;
  if (type === AutoChargeType.PAYMENT_LINK) {
    paymentLinkReceiver = await selectPaymentLinkReceiver(props.booking);
    if (!paymentLinkReceiver) return;
  }

  const chargeAmountDto: ChargeAmountDto = {
    bookingId: props.booking.id,
    invoiceId: props.invoiceId,
    amount: Math.abs(Number(values.amount)) * Number(values.direction),
    types: [type],
    creditCardToUse,
    paymentLinkReceiver,
  };
  if (values.invoiceId) chargeAmountDto.invoiceId = values.invoiceId;
  emit('onSubmit', chargeAmountDto);
});

const directions = ref([
  {
    label: t('payment'),
    value: '1',
  },
  {
    label: t('repayment'),
    value: '-1',
  },
]);

const paymentMethodOptions = computed(() => [
  ...(props.showOnlinePaymentCard
    ? [
        {
          label: `${t('creditCardOnlinePayment')} (${
            props.booking.onlineCreditCardDetails?.number ?? ''
          })`,
          value: ChargeAmountPaymentMethod.ONLINE_PAYMENT_CARD,
        },
      ]
    : []),
  ...(props.showBlockedCard
    ? [
        {
          label: `${t('creditCardDeposit')} (${
            props.booking.blockedCreditCardDetails?.number ?? ''
          })`,
          value: ChargeAmountPaymentMethod.DEPOSIT_CARD,
        },
      ]
    : []),
  ...(props.showOnlinePaymentCard || props.booking.status === BookingStatus.CAR_RETURNED
    ? [
        {
          label: t('paymentLink'),
          value: ChargeAmountPaymentMethod.PAYMENT_LINK,
        },
      ]
    : []),
]);

const invoicesOptions = computed(() => [
  { label: t('noInvoice'), value: null },
  ...props.invoices.map((invoice) => ({
    label: `${invoice.completeInvoiceNumber ?? t('iNrNA')} - ${invoice.title ?? ''}`,
    value: invoice.id,
  })),
]);
</script>

<i18n lang="json">
{
  "en": {
    "invoice": "Invoice",
    "amount": "Amount",
    "chargeAmount": "Charge Amount",
    "noInvoice": "- No Invoice selected -",
    "reallyChargeTitle": "Really charge from Customer?",
    "reallyChargeFromCreditCardText": "The specified amount is debited directly from the customer's credit card.",
    "reallyChargeViaPaylinkText": "The specified amount is requested via a payment link.",
    "confirmCharge": "Charge Amount",
    "declineCharge": "Cancel",
    "payment": "Payment",
    "repayment": "Repayment",
    "direction": "Direction",
    "paymentMethod": "Payment Method",
    "creditCardOnlinePayment": "Credit Card from Online Payment",
    "creditCardDeposit": "Credit Card from Deposit",
    "paymentLink": "Payment Link"
  },
  "de": {
    "invoice": "Rechnung",
    "amount": "Betrag",
    "chargeAmount": "Betrag abbuchen",
    "noInvoice": "- Keine Rechnung ausgewählt -",
    "reallyChargeTitle": "Wirklich vom Kunden abbuchen?",
    "reallyChargeFromCreditCardText": "Der angegebene Betrag wird direkt von der Kreditkarte des Kunden abgebucht.",
    "reallyChargeViaPaylinkText": "Der angegebene Betrag wird über einen Zahlungslink eingefordert.",
    "confirmCharge": "Betrag abbuchen",
    "declineCharge": "Abbrechen",
    "payment": "Zahlung",
    "repayment": "Rückzahlung",
    "direction": "Richtung",
    "paymentMethod": "Zahlungsart",
    "creditCardOnlinePayment": "Kreditkarte von Online-Zahlung",
    "creditCardDeposit": "Kreditkarte von Kaution",
    "paymentLink": "Zahlungslink"
  }
}
</i18n>
