import {
  type VehicleExtraOption,
  VehicleExtraOptionPricingType,
} from '@/entities/vehicle-extra-option.entity';

/**
 * Calculates the total option price
 * @param option The option its price is to be calculated
 * @param totalRentalDays The amount of days the price will be calculated based on
 * @returns The calculated price of an option
 */
export const getCalculatedExtraOptionPrice = (
  option: VehicleExtraOption,
  totalRentalDays: number | null = 1,
) => {
  if (!option) return 0;
  switch (option.pricingType) {
    case VehicleExtraOptionPricingType.FIXED:
      return option.price ?? 0;
    case VehicleExtraOptionPricingType.FIXED_PER_DAY:
      return (
        option.pricePerDay.reduce((prev, cur) =>
          cur.fromDay > prev.fromDay && cur.fromDay <= (totalRentalDays ?? 1) ? cur : prev,
        ).pricePerDay * (totalRentalDays ?? 1)
      );
  }
};
