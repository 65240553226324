<template>
  <button
    class="flex gap-4 rounded-2xl border border-transparent bg-primary/10 p-4 text-left transition duration-300 hover:scale-[1.03] hover:border-primary hover:bg-white"
    :class="{ 'opacity-50': !offer.isAvailable }"
  >
    <div class="basis-[55%]">
      <p class="text-lg font-medium leading-tight">
        {{ offer.car.vehicleType!.make.name }} {{ offer.car.vehicleType!.model }}
      </p>
      <img v-if="imageUrl" :src="imageUrl" />
    </div>
    <div class="basis-[45%]">
      <p class="mb-3 mt-1 font-medium">
        {{ t('inclKm', { inclusiveKm }) }}
      </p>
      <p>{{ power }} {{ t('hp') }}</p>
      <p class="mb-3">{{ seats }}-{{ t('seater') }}</p>
      <p class="text-lg font-medium text-primary">
        <span v-currency="props.offer.pricing.averagePricePerDay" /> / {{ t('day') }}
      </p>
      <p class="text-sm text-black/30">
        <span v-currency="offer.pricing.totalPrice" /> {{ t('total') }}
      </p>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getInclusiveKm } from '@/utils/get-inclusive-km';
import { useI18n } from 'vue-i18n';
import type { Offer } from '@carvia/ros-client-types';

const props = defineProps<{
  offer: Offer;
  rentDuration: number;
}>();

const { t } = useI18n();

const imageUrl = computed(
  () => props.offer.car.imageUrl ?? props.offer.car.vehicleType!.imageUrl ?? undefined,
);

const inclusiveKm = computed(() =>
  getInclusiveKm(props.offer.car.vehicleType!.inclusiveKm, props.rentDuration),
);

const power = computed(() => props.offer.car.power ?? props.offer.car.vehicleType!.power);
const seats = computed(() => props.offer.car.seats ?? props.offer.car.vehicleType!.seats);
</script>

<i18n lang="json">
{
  "en": {
    "inclKm": "Incl. {inclusiveKm} km",
    "hp": "hp",
    "seater": "seater",
    "day": "day",
    "total": "total"
  },
  "de": {
    "inclKm": "Inkl. {inclusiveKm} km",
    "hp": "PS",
    "seater": "Sitzer",
    "day": "Tag",
    "total": "Gesamt"
  }
}
</i18n>
