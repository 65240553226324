import type { BookedExtraDto } from '@/entities/bookings/booked-extra';
import type { BookingStatus } from '@/entities/bookings/booking-status.enum';
import type { BookingType } from '@/entities/bookings/booking-type.enum';
import type { PaginationParams } from '@/entities/pagination/pagination-params';
import type { User } from '../auth/user.entity';
import type { Car } from '../cars/car.entity';
import type { Damage } from '../damage.entity';
import type { InclusiveKmPerDay } from '../inclusive-km-per-day.entity';
import type { Invoice } from '../invoices/invoice.entity';
import type { Payment } from '../payments/payment.entity';
import type { Pricing } from '../pricing.entity';
import type { Station } from '../station/station.entity';
import type { BookingLocationType } from './booking-location-type.enum';
import type { FindAllBookingsSort } from './find-all-bookings-sort.enum';
import type { RentalPartner } from './rental-partner.enum';
import type { CustomerData } from './customer-data';
import type { CustomerDataDto } from './customer-data.dto';
import type { CreditCardDetails } from './credit-card-details';
import type { CancellationFee } from '@carvia/ros-backend/src/bookings/constants/cancellation-fees';
import type { CancelledBy } from '@carvia/ros-backend/src/bookings/dto/cancelled-by.enum';

export interface Booking {
  id: string;
  bookingNumber: number;
  type: BookingType;
  startDate: string;
  handoverDate: string;
  endDate: string;
  returnDate: string;
  customer?: User;
  customerData?: CustomerData;
  invoices: Invoice[];
  car: Car;
  initiallyBookedCar: Car | null;
  pickupLocationType: BookingLocationType;
  dropoffLocationType: BookingLocationType;
  pickupStation: Station | null;
  pickupLocationGeocodedAddress?: LocationGeocodedName | null;
  dropoffStation: Station | null;
  pickupLocation: LocationPoint | null;
  dropoffLocation: LocationPoint | null;
  dropoffLocationGeocodedAddress?: LocationGeocodedName | null;
  status: BookingStatus;
  milageStart: number | null;
  milageEnd: number | null;
  payments: Payment[];
  damages: Damage[] | null;
  fuelLevelStart: number | null;
  fuelLevelEnd: number | null;
  blockedCreditCardAmount: number | null;
  blockedCreditCardDate: string | null;
  blockedCreditCardRefNr: string | null;
  blockedCreditCardIncludesOpenFee: boolean | null;
  createdDepositPaymentLinkDate: string | null;
  createdPaymentLinkDate: string | null;
  priceCalculation: Pricing;
  customerComment: string | null;
  internalComment: string | null;
  createdDate: string;
  updatedDate: string;
  customInclusiveKm: number | null;
  totalIncludedKms: number;
  totalRentalDays: number;
  inclusiveKm: InclusiveKmPerDay[];
  calculatedInclusiveKm: number | null;
  additionalDrivers: AdditionalDriver[];
  contractFileKey: string | null;
  contractFileUrl?: string;
  confirmationFileUrl?: string;
  pricePerExtraKm: number;
  deposit: number | null;
  totalMilage: number | null;
  totalMilageExceeded: number | null;
  totalDurationDays: number;
  totalDurationExceeded: number;
  fuelLevelDifference: number | null;
  currentBalance: number;
  relatedBookings: Booking[];
  agent: User | null;
  rentalPartner: RentalPartner | null;
  hasBlockedCreditCard: boolean;
  hasOnlineCreditCardPayment: boolean;
  blockedCreditCardDetails: CreditCardDetails | null;
  onlineCreditCardDetails: CreditCardDetails | null;
  hasUnhandledDamages: boolean;
}

export class AdditionalDriver {
  firstName = '';
  lastName = '';
  dateOfBirth: string | null = null;
  licenseNumber = '';
  licenseDate: string | null = null;
  licenseAuthority = '';
}

export interface LocationPoint {
  type: 'Point';
  coordinates: [number, number];
}

export interface BookingLocation {
  locationType: BookingLocationType;
  station: Station | null;
  location: GeocodedLocation | null;
}

export interface BookingCoordinates {
  longitude: number;
  latitude: number;
}

export interface LocationGeocodedName {
  displayName: string;
}

export interface GeocodedLocation {
  address?: LocationGeocodedName | null;
  coordinates: BookingCoordinates | null;
}

export interface GetContractSignatureUploadUrlDto {
  bookingId: string;
}

export interface CreateBookingDto {
  type: BookingType;
  startDate: Date | string;
  endDate: Date | string;
  carId: string;
  pickupLocationType: BookingLocationType;
  pickupStationId?: string | null;
  pickupLocation?: BookingCoordinates | null;
  pickupLocationGeocodedAddress?: LocationGeocodedName | null;
  dropoffLocationType: BookingLocationType;
  dropoffStationId?: string | null;
  dropoffLocation?: BookingCoordinates | null;
  dropoffLocationGeocodedAddress?: LocationGeocodedName | null;
  customerId?: string | null;
  customerData?: CustomerDataDto;
  milageStart?: number | null;
  fuelLevelStart?: number | null;
  priceCalculationId: string;
  customerComment?: string | null;
  internalComment?: string | null;
  bookedExtras: BookedExtraDto[];
  additionalDrivers: AdditionalDriver[] | null;
  relatedBookingIds?: string[];
  agentId?: string | null;
  rentalPartner?: RentalPartner | null;
  status?: BookingStatus;
}

export interface CreateBookingAsAgentDto {
  carId: string;
  customerComment?: string | null;
  customerData?: CustomerDataDto;
  customerId?: string | null;
  dropoffLocation?: BookingCoordinates | null;
  dropoffLocationGeocodedAddress?: LocationGeocodedName | null;
  dropoffLocationType?: BookingLocationType;
  dropoffStationId?: string | null;
  endDate: Date | string;
  pickupLocation?: BookingCoordinates | null;
  pickupLocationGeocodedAddress?: LocationGeocodedName | null;
  pickupLocationType?: BookingLocationType;
  pickupStationId?: string | null;
  priceCalculationId: string;
  startDate: Date | string;
}

export interface UpdateBookingDto extends Partial<CreateBookingDto> {
  milageEnd?: number | null;
  fuelLevelEnd?: number | null;
  status?: BookingStatus;
  inclusiveKm?: InclusiveKmPerDay[];
  calculatedInclusiveKm?: number;
  customInclusiveKm?: number | null;
  additionalDrivers?: AdditionalDriver[];
  contractFileKey?: string;
  blockedCreditCardAmount?: number | null;
  blockedCreditCardDate?: string | null;
  blockedCreditCardIncludesOpenFee?: boolean | null;
  returnDate?: string | null;
  pricePerExtraKm?: number;
  deposit?: number;
  hasUnhandledDamages?: boolean;
}

export interface UpdateBookingQueryDto {
  cancelledBy?: CancelledBy;
  overrideCancellationFee?: CancellationFee;
  overrideCancellationFeeComment?: string;
  sendCancelledMail?: boolean;
}

export interface FindAllBookingsQueryDto extends PaginationParams<FindAllBookingsSort> {
  filter?: FindAllBookingsFilterDto[];
}

export interface FindAllBookingsFilterDto {
  types?: BookingType[];
  bookingNumbers?: number[];
  pickupStationIds?: string[] | null;
  dropoffStationIds?: string[] | null;
  startDateBefore?: string;
  startDateAfter?: string;
  endDateBefore?: string;
  endDateAfter?: string;
  status?: BookingStatus[];
  vehicleTypeIds?: string[];
  carIds?: string[];
  carStationIds?: string[];
  customerIds?: string[];
  agentIds?: string[];
  query?: string;
  hasTransfers?: boolean;
  hasUnhandledDamages?: boolean;
}

export type AdditionalDriversFormValues = AdditionalDriver[];
