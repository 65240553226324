<template>
  <h2>{{ t('payments') }}</h2>
  <div v-if="payments" class="relative mt-4">
    <div>
      <div
        v-for="(payment, i) in payments"
        :key="i"
        class="relative flex gap-2 border-b border-primary/40 py-2"
      >
        <div
          class="flex w-full items-center gap-4"
          :class="{
            'opacity-30': payment.status === PaymentStatus.CANCELLED,
          }"
        >
          <div class="basis-[14%]">
            {{ formatDateString(payment.date, dateFormat) }}
          </div>
          <div class="basis-[20%]">
            <span v-currency="payment.amount"></span>
            <span
              v-if="payment.status === PaymentStatus.AUTHORIZED"
              class="ml-2 rounded-full bg-yellow-100 px-2 py-0.5 text-sm uppercase text-yellow-800"
              :title="t('authorizedDescription')"
            >
              {{ t('authorized') }}
            </span>
          </div>
          <div class="basis-[8%]">
            {{ t(`paymentTypes.${payment.paymentType}`) }}
          </div>
          <div class="basis-[15%]">
            {{ payment.creditCardDetails?.source ?? '-' }}
          </div>
          <div class="basis-[13%]">
            {{ payment.invoice?.completeInvoiceNumber ?? t('iNrNA') }}
          </div>
          <div class="basis-[30%]">
            {{ payment.referenceId }}
          </div>
        </div>
        <DropdownDotted :buttons="dropdownButtons[i]" />
      </div>
      <div v-if="payments.length === 0" class="pb-3 pt-1">
        {{ t('noPayments') }}
      </div>
    </div>

    <PaymentsForm
      v-if="addingPayment"
      :booking-id="booking?.id"
      :invoice-id="invoiceId"
      :invoices="invoices"
      @on-submit="savePayment"
      @on-cancel="addingPayment = false"
    />

    <ChargeAmountForm
      v-if="chargingAmount && booking"
      :booking="booking"
      :invoice-id="invoiceId"
      :invoices="invoices"
      :show-online-payment-card="showOnlinePaymentCard"
      :show-blocked-card="showBlockedCard"
      @on-submit="chargeAmount"
      @on-cancel="chargingAmount = false"
    />

    <div v-else class="mt-5 flex justify-end gap-4">
      <CVButton
        v-if="showChargeRemainingAmountButton && (booking?.currentBalance ?? 0) < 0"
        :disabled="remainingAmountCharged"
        @click.prevent="chargeRemainingBookingAmount"
        >{{ t('chargeRemainingAmount') }}</CVButton
      >
      <CVButton :outline="noPaymentOptionsAvailable" @click.prevent="chargeIndividualAmount">{{
        t('chargeIndividualAmount')
      }}</CVButton>
      <CVButton @click.prevent="addingPayment = true">{{ t('addPayment') }}</CVButton>
    </div>
    <div v-if="booking?.createdPaymentLinkDate" class="mt-4">
      <p>
        {{ t('createPaymentLinkAt') }}
        {{ formatDateString(booking?.createdPaymentLinkDate, dateFormat) }}
      </p>
    </div>
    <div
      v-if="isCreating || isDeleting || isChargingAmount || isChargingRemainingAmount"
      class="absolute inset-[-10px] flex items-center justify-center bg-black bg-opacity-30"
    >
      <Spinner />
    </div>
  </div>

  <div v-else class="flex h-20 items-center justify-center">
    <Spinner />
  </div>

  <CreditCardDetailsModal
    v-if="creditCardDetails"
    v-model="showCreditCardDetails"
    :credit-card-details="creditCardDetails"
  />
</template>

<script lang="ts" setup>
import { FindAllPaymentsSort } from '@/entities/find-all-payments-sort.enum';
import { Order } from '@/entities/pagination/order.enum';
import { PaymentStatus } from '@/entities/payments/payment-status.enum';
import type { CreatePaymentDto } from '@/entities/payments/payment.entity';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import { useCreatePayment, usePaymentsWithQuery, useUpdatePayment } from '@/queries/use-payments';
import { Alert } from '@/utils/alert';
import { computed, ref } from 'vue';
import PaymentsForm from './PaymentsForm.vue';
import { useChargeAmount, useChargeRemainingBookingAmount } from '@/queries/use-payment-api';
import type { ChargeAmountDto } from '@/entities/payment-terminals/charge-amount.dto';
import ChargeAmountForm from './ChargeAmountForm.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import DropdownDotted from '../DropdownDotted.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { AutoChargeType } from '@/entities/payment-terminals/auto-charge-type.enum';
import type { CreditCardDetails } from '@/entities/bookings/credit-card-details';
import CreditCardDetailsModal from './CreditCardDetailsModal.vue';
import { useInvoicesOfBooking } from '@/hooks/use-invoices-of-booking';
import { useAvailableCreditCards } from '@/hooks/use-available-credit-cards';
import {
  selectPaymentLinkReceiver,
  showPaymentLinkToCopy,
} from '@/utils/select-payment-link-receiver';
import { formatDateString } from '@/hooks/use-formated-date';
import { PaymentLinkReceiver, SendPaymentLinkResult } from '@carvia/ros-client-types';

const props = defineProps<{
  booking?: Booking | null;
  invoiceId?: string;
}>();

const emit = defineEmits<{
  (e: 'onChanged'): void;
}>();

const { t } = useI18n();

const addingPayment = ref(false);
const chargingAmount = ref(false);
const remainingAmountCharged = ref(false);
const showCreditCardDetails = ref(false);
const creditCardDetails = ref<CreditCardDetails | null>(null);

const showChargeRemainingAmountButton = computed(
  () =>
    props.booking?.status === BookingStatus.DRAFT ||
    props.booking?.status === BookingStatus.OPEN ||
    props.booking?.status === BookingStatus.CONFIRMED ||
    props.booking?.status === BookingStatus.FLOATING,
);

const { showBlockedCard, showOnlinePaymentCard } = useAvailableCreditCards(
  computed(() => props.booking),
);

const dateFormat = 'dd.MM.yy';

const bookingIds = computed(() => (props.booking ? [props.booking.id] : undefined));
const invoiceIds = computed(() => (props.invoiceId ? [props.invoiceId] : undefined));

const params = computed(() => ({
  bookingIds: bookingIds.value,
  invoiceIds: invoiceIds.value,
  limit: 100,
  sort: FindAllPaymentsSort.CREATED_DATE,
  order: Order.ASC,
}));

const hasBookingOrInvoiceId = computed(() => !!(props.booking || props.invoiceId));

const { data: paymentsData, refetch: refetchPayments } = usePaymentsWithQuery(
  params,
  hasBookingOrInvoiceId,
);
const { mutateAsync: createPayment, isPending: isCreating } = useCreatePayment();
const { mutateAsync: updatePayment, isPending: isDeleting } = useUpdatePayment();
const { mutateAsync: charge, isPending: isChargingAmount } = useChargeAmount();
const { mutateAsync: chargeRemaining, isPending: isChargingRemainingAmount } =
  useChargeRemainingBookingAmount();
const invoices = useInvoicesOfBooking(computed(() => props.booking?.id));
const payments = useFlattenPaginatedData(paymentsData);

const savePayment = async (newPayment: CreatePaymentDto) => {
  await createPayment(newPayment);
  addingPayment.value = false;
  emit('onChanged');
};

const chargeAmount = async (chargeAmountDto: ChargeAmountDto) => {
  refetchPayments();
  chargingAmount.value = false;
  emit('onChanged');
  const { paymentLink } = await charge(chargeAmountDto);
  paymentLink?.sendPaymentLinkResult === SendPaymentLinkResult.DISABLED &&
    showPaymentLinkToCopy(paymentLink.url);
};

const noPaymentOptionsAvailable = computed(
  () => props.booking?.status === BookingStatus.HANDED_OVER && !props.booking?.hasBlockedCreditCard,
);

const chargeIndividualAmount = async () => {
  if (noPaymentOptionsAvailable.value) {
    await Alert.fire({
      icon: 'error',
      text: t('noPaymentOptionsAvailable'),
      confirmButtonText: t('ok'),
    });
    return;
  }
  chargingAmount.value = true;
};

const chargeRemainingBookingAmount = async () => {
  if (!props.booking) return;
  const { isConfirmed } = await Alert.fire({
    text: t('reallyChargeRemainingAmountText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('continue'),
    denyButtonText: t('cancel'),
  });
  if (!isConfirmed) return;

  const paymentLinkReceiver = await selectPaymentLinkReceiver(props.booking);
  if (!paymentLinkReceiver) return;

  const { paymentLink } = await chargeRemaining({
    bookingId: props.booking.id,
    invoiceId: props.invoiceId,
    types: [AutoChargeType.PAYMENT_LINK],
    paymentLinkReceiver,
  });
  remainingAmountCharged.value = true;
  refetchPayments();
  emit('onChanged');
  if (paymentLinkReceiver === PaymentLinkReceiver.NONE && paymentLink) {
    showPaymentLinkToCopy(paymentLink.url);
  }
};

const cancelPayment = async (paymentId: string) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyCancelPaymentTitle'),
    text: t('reallyCancelPaymentText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('confirmCancelPayment'),
    denyButtonText: t('declineCancelPayment'),
  });

  if (alertResult.isConfirmed) {
    await updatePayment({
      id: paymentId,
      payment: {
        status: PaymentStatus.CANCELLED,
      },
    });
    emit('onChanged');
  }
};

const dropdownButtons = computed(() =>
  payments.value.map((payment) => [
    {
      title: t('cancelPayment'),
      onClick: () => cancelPayment(payment.id),
    },
    ...(payment.creditCardDetails
      ? [
          {
            title: t('showCreditCardDetails'),
            onClick: () => {
              creditCardDetails.value = payment.creditCardDetails;
              showCreditCardDetails.value = true;
            },
          },
        ]
      : []),
  ]),
);
</script>

<i18n lang="json">
{
  "en": {
    "payments": "Payments",
    "addPayment": "+ Enter Payment",
    "chargeIndividualAmount": "+ Charge Individual Amount",
    "chargeRemainingAmount": "+ Claim Remaining Amount",
    "specifyAmount": "Specify Amount",
    "reallyCancelPaymentTitle": "Really cancel Payment?",
    "reallyCancelPaymentText": "The Payment will be permanently canceled.",
    "confirmCancelPayment": "Cancel Payment",
    "declineCancelPayment": "Don't cancel",
    "noPayments": "This booking has no payments",
    "iNrNA": "INr. n/a",
    "cancelPayment": "Cancel",
    "payment": "Payment",
    "repayment": "Repayment",
    "reallyChargeRemainingAmountText": "A payment link with the remaining rental amount will be created. Really charge remaining amount?",
    "sendPaymentLink": "Send Payment Link",
    "createPaymentLinkAt": "Created last payment link at:",
    "showCreditCardDetails": "Show Credit Card Details",
    "noPaymentOptionsAvailable": "No payment options available for this booking.",
    "ok": "Ok",
    "authorized": "Auth.",
    "authorizedDescription": "The payment has been authorized by the customer but not yet captured."
  },
  "de": {
    "payments": "Zahlungen",
    "addPayment": "+ Zahlung eintragen",
    "chargeIndividualAmount": "+ Individuellen Betrag abbuchen",
    "chargeRemainingAmount": "+ Offenen Restbetrag einfordern",
    "specifyAmount": "Betrag angeben",
    "reallyCancelPaymentTitle": "Zahlung wirklich stornieren?",
    "reallyCancelPaymentText": "Die Zahlung wird unwiderruflich storniert.",
    "confirmCancelPayment": "Zahlung stornieren",
    "declineCancelPayment": "Nicht stornieren",
    "noPayments": "Diese Buchung hat noch keine Zahlungen",
    "iNrNA": "Rnr. n/a",
    "cancelPayment": "Stornieren",
    "payment": "Zahlung",
    "repayment": "Rückzahlung",
    "reallyChargeRemainingAmountText": "Es wird ein Zahlungslink mit dem offenen Mietbetrag erstellt. Wirklich offenen Restbetrag einfordern?",
    "sendPaymentLink": "Zahlungslink verschicken",
    "createPaymentLinkAt": "Letzter Zahlungslink erstellt am:",
    "showCreditCardDetails": "Kreditkarten Details anzeigen",
    "noPaymentOptionsAvailable": "Für diese Buchung sind keine Zahlungsoptionen verfügbar.",
    "ok": "Ok",
    "authorized": "Auto.",
    "authorizedDescription": "Die Zahlung wurde vom Kunden autorisiert, aber noch nicht einezogen."
  }
}
</i18n>
