<template>
  <div class="grid grid-cols-3 gap-x-10">
    <div class="col-span-2 flex items-center">
      <div>
        <h4>{{ t('vehicle') }}</h4>
        <div class="flex min-h-max items-start gap-4">
          <div class="basis-[55%]">
            <img :src="imageUrl" class="w-full" />
          </div>
          <div class="mt-2 flex h-min basis-[45%] flex-col space-y-1">
            <p class="text-sm font-medium lg:text-base">
              {{ booking.car?.vehicleType?.make.name }}
              {{ booking.car?.vehicleType?.model }}
            </p>
            <p class="font-normal">{{ booking.car?.licencePlate }}</p>
            <p class="font-normal">FIN: {{ booking.car?.fin }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-start-3 h-min">
      <div class="-mb-2 flex flex-col space-y-2">
        <LabeledText :label="t('startDate')">
          {{ formattedStartDate }}
        </LabeledText>
        <div v-if="!hideLocations">
          <p v-if="booking.pickupLocationType === BookingLocationType.STATION">
            {{ getLocalizedField(booking.pickupStation?.info) }}
          </p>
          <div v-else>
            <p v-if="!isFetchingPickupLocation">
              {{
                booking.pickupLocationGeocodedAddress?.displayName ??
                pickupLocationData?.address?.displayName
              }}
            </p>
            <div v-else class="flex justify-center"><Spinner /></div>
          </div>
        </div>
      </div>

      <Divider />

      <div class="-mt-2 flex flex-col space-y-2">
        <LabeledText :label="t('endDate')">
          <Datepicker
            v-if="useReturnDate"
            :model-value="returnDate"
            :format="dateTimeFormat"
            enable-time-picker
            :clearable="false"
            @update:model-value="(value: Date) => (returnDate = value.toISOString())"
          />
          <span v-else>{{ formattedEndDate }}</span>
        </LabeledText>
        <div v-if="!hideLocations">
          <p v-if="booking.dropoffLocationType === BookingLocationType.STATION">
            {{ getLocalizedField(booking.dropoffStation?.info) }}
          </p>
          <div v-else>
            <p v-if="!isFetchingDropoffLocation">
              {{
                booking.dropoffLocationGeocodedAddress?.displayName ??
                dropoffLocationData?.address?.displayName
              }}
            </p>
            <div v-else class="flex justify-center"><Spinner /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Divider from '@/components/Divider.vue';
import LabeledText from '@/components/LabeledText.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useDateTimeFormat } from '@/hooks/use-date-format';
import { useFormatedIsoDateString } from '@/hooks/use-formated-date';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import { useLocationsData } from '@/hooks/use-location-data';
import { pointToLocationDto } from '@/hooks/use-location-to-coordinates';
import { computed, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const props = defineProps<{
  booking: Booking;
  useReturnDate?: boolean;
  hideLocations?: boolean;
}>();

const emit = defineEmits<{
  (event: 'onReturnDateChange', returnDate: string): void;
}>();

const returnDate = ref(props.booking.returnDate ?? props.booking.endDate);
watchEffect(() => {
  emit('onReturnDateChange', returnDate.value);
});

const dateTimeFormat = useDateTimeFormat();
const formattedStartDate = useFormatedIsoDateString(props.booking.startDate);
const formattedEndDate = useFormatedIsoDateString(props.booking.endDate);

const imageUrl = computed(
  () => props.booking.car.imageUrl ?? props.booking.car.vehicleType.imageUrl,
);

const {
  pickupLocationData,
  isFetchingPickupLocation,
  dropoffLocationData,
  isFetchingDropoffLocation,
} = useLocationsData(
  computed(() => ({
    pickupLocationType: props.booking.pickupLocationType,
    dropoffLocationType: props.booking.dropoffLocationType,
    pickupLocation: pointToLocationDto(props.booking.pickupLocation),
    dropoffLocation: pointToLocationDto(props.booking.dropoffLocation),
    pickupLocationGeocodedAddress: props.booking.pickupLocationGeocodedAddress,
    dropoffLocationGeocodedAddress: props.booking.dropoffLocationGeocodedAddress,
  })),
);
</script>

<i18n lang="json">
{
  "en": {
    "customerComment": "Customer Comment",
    "returnOverview": "Return overview",
    "renter": "Renter",
    "bookingNumber": "Booking Nr.",
    "vehicle": "Vehicle",
    "startDate": "Start of rental",
    "endDate": "End of rental"
  },
  "de": {
    "customerComment": "Kundenkommentar",
    "returnOverview": "Buchungsübersicht",
    "renter": "Mieter",
    "bookingNumber": "Buchungs Nr.",
    "vehicle": "Fahrzeug",
    "startDate": "Mietbeginn",
    "endDate": "Mietende"
  }
}
</i18n>
